import { useQuery } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { OwnerTypeDto } from '../../shared/types/owner';

const ownerKeys = {
  all: ['owners'],
  ownerSelectOptions: (filter: string) => [...ownerKeys.all, 'ownerSelectOptions', filter],
};

export type OwnerSelectOptionDto = {
  id: number;
  type: OwnerTypeDto;
  name: string;
  pid: string;
};

export const useOwnerSelectOptionsQuery = (filter: string) =>
  useQuery({
    queryKey: ownerKeys.ownerSelectOptions(filter),
    queryFn: async () => {
      const response = await apiRequest2<OwnerSelectOptionDto[]>({
        action: `Admin/Owners/SelectOptions`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
        },
      });

      return response.data;
    },
  });
