import { DataGrid } from '@mui/x-data-grid';
import { useAddProductMutation, useProductsQuery, useUpdateProductMutation } from '../../api/admin/products';
import { DataGridHeader, DefaultButton, FilterInput, PageTitle } from '../../layout';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { AddProductDialog } from '../../components/admin/products/AddProductDialog';
import { useDebounce } from '@uidotdev/usehooks';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useDeleteProductMutation } from './../../api/admin/products';
import { EditProductDialog } from '../../components/admin/products/EditProductDialog';
import { formatMoney } from '../../shared/formatters/money';

export const EditProductsPage = () => {
  const [filter, setFilter] = useState<string>('');
  const [isAddPersonDialogOpen, setIsAddPersonDialogOpen] = useState(false);
  const [productIdToEdit, setProductIdToEdit] = useState<number | null>(null);
  const [productIdToDelete, setProductIdToDelete] = useState<number | null>(null);

  const debouncedFilter = useDebounce(filter, 500);

  const productsQuery = useProductsQuery(debouncedFilter);
  const addProductMutation = useAddProductMutation();
  const updateProductMutation = useUpdateProductMutation();
  const deleteProductMutation = useDeleteProductMutation();

  return (
    <>
      <AddProductDialog
        open={isAddPersonDialogOpen}
        onSave={(name, description, price) => addProductMutation.mutate({ name, description, price })}
        onClose={() => setIsAddPersonDialogOpen(false)}
      />
      <EditProductDialog
        id={productIdToEdit}
        onSave={(id, name, description, price) => updateProductMutation.mutate({ id, name, description, price })}
        onClose={() => setProductIdToEdit(null)}
      />
      <ConfirmDialog
        title="Radera produkt"
        message="Är du säker på att du vill radera produkten?"
        isOpen={productIdToDelete !== null}
        onConfirm={() => {
          if (productIdToDelete !== null) {
            deleteProductMutation.mutate(productIdToDelete);
          }
          setProductIdToDelete(null);
        }}
        onCancel={() => setProductIdToDelete(null)}
      />
      <PageTitle>Redigera produkter</PageTitle>
      <DataGridHeader>
        <FilterInput
          type="search"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          placeholder="Filter"
        />

        <DefaultButton onClick={() => setIsAddPersonDialogOpen(true)}>
          <AddIcon />
          Lägg till ny produkt
        </DefaultButton>
      </DataGridHeader>

      {productsQuery.isError && <div>Error: {productsQuery.error.message}</div>}
      <DataGrid
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'name', headerName: 'Namn', flex: 1 },
          {
            field: 'price',
            headerName: 'Pris',
            align: 'right',
            type: 'number',
            valueFormatter: (params) => {
              return formatMoney(params.value);
            },
          },
          {
            field: 'actions',
            headerName: '',
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params) => (
              <>
                <IconButton area-label="Ändra" onClick={() => setProductIdToEdit(params.row.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton area-label="Radera" onClick={() => setProductIdToDelete(params.row.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            ),
          },
        ]}
        rows={productsQuery.data ?? []}
        autoHeight
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={10}
        loading={productsQuery.isLoading}
        sx={{
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
            marginTop: '1em',
            marginBottom: '1em',
          },
        }}
      />
    </>
  );
};
