import { useProductQuery } from '../../../api/admin/products';
import { MoneyDto } from '../../../shared/types/money';
import { ProductDialog } from './ProductDialog';

type EditProductDialogParams = {
  id: number | null;
  onSave: (id: number, name: string, description: string | null, price: MoneyDto) => void;
  onClose: () => void;
};

export const EditProductDialog = ({ id, onSave, onClose }: EditProductDialogParams) => {
  const productQuery = useProductQuery(id);

  return (
    <>
      {productQuery.data && !productQuery.isFetching && (
        <ProductDialog
          open={id !== null && productQuery.data !== undefined}
          title="Ändra Produkt"
          initialValues={productQuery.data}
          onSave={(id: number, name: string, description: string | null, price: MoneyDto): void => {
            onSave(id, name, description, price);
          }}
          onClose={(): void => {
            onClose();
          }}
        />
      )}
    </>
  );
};
