import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { DefaultButton, DefaultTextButton } from '../../../layout';
import { MoneyDto } from '../../../shared/types/money';
import { getCurrencySymbol } from '../../../shared/formatters/money';

type ProductDialogParams = {
  open: boolean;
  title: string;
  initialValues: { id: number; name: string; description: string | null; price: MoneyDto };
  onSave: (id: number, name: string, description: string | null, price: MoneyDto) => void;
  onClose: () => void;
};

export const ProductDialog = ({ open, title, initialValues, onSave, onClose }: ProductDialogParams) => {
  const [name, setName] = useState<string>(initialValues.name);
  const [description, setDescription] = useState<string>(initialValues.description ?? '');
  const [price, setPrice] = useState<MoneyDto>(initialValues.price);

  function IsValid() {
    return name && price !== null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <FormControl>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            label="Namn"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
          />
          <TextField
            label="Beskrivning"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="dense"
            multiline
            rows={4}
            variant="outlined"
          />
          <TextField
            label="Pris"
            type="text"
            inputMode="numeric"
            value={price.amount}
            onChange={(e) => setPrice((prev) => ({ ...prev, amount: Number(e.target.value) }))}
            fullWidth
            margin="dense"
            variant="outlined"
            inputProps={{
              sx: { textAlign: 'right' },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{getCurrencySymbol(price.currency)}</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton
            disabled={!IsValid()}
            onClick={() => {
              if (initialValues !== null && name && price !== null) {
                onSave(initialValues.id, name, description, price);
              }
              onClose();
            }}
          >
            Spara
          </DefaultButton>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
};
