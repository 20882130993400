import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { ProductSelectOptionDto, useProductSelectOptionsQuery } from '../../../api/admin/products';
import { useState } from 'react';

type ProductSelectProps = {
  onSelect: (product: ProductSelectOptionDto | null) => void;
};

export function ProductSelect({ onSelect }: ProductSelectProps) {
  const [value, setValue] = useState<ProductSelectOptionDto | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const productSelectOptionsQuery = useProductSelectOptionsQuery(inputValue);

  return (
    <Autocomplete
      loading={productSelectOptionsQuery.isLoading}
      options={productSelectOptionsQuery.data ?? []}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_event, newValue) => {
        setValue(newValue);
        onSelect(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText="Inga alternativ"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ägare"
          variant="outlined"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {productSelectOptionsQuery.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} value={option.name}>
          {option.name}
        </MenuItem>
      )}
      fullWidth
    />
  );
}
