import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { OrderDto, OrderStatusDto, useOrderQuery } from '../../../api/admin/orders';
import { OwnerSelect } from '../owners/OwnerSelect';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrderItemDialog } from './OrderItemDialog';
import { getCurrencySymbol } from '../../../shared/formatters/money';

const orderStatusOptions = [
  { value: OrderStatusDto.New, label: 'Ny' },
  { value: OrderStatusDto.Processing, label: 'Bearbetning' },
  { value: OrderStatusDto.Shipped, label: 'Skickad' },
  { value: OrderStatusDto.Delivered, label: 'Levererad' },
  { value: OrderStatusDto.Canceled, label: 'Avbruten' },
  { value: OrderStatusDto.Returned, label: 'Returnerad' },
  { value: OrderStatusDto.OnHold, label: 'Hållen' },
];

export function OrderDialog({
  open,
  onSave,
  onClose,
  orderIdToEdit,
}: {
  open: boolean;
  onSave: (item: OrderDto) => void;
  onClose: () => void;
  orderIdToEdit: number | null;
}) {
  const [order, setOrder] = useState<OrderDto>({
    id: null,
    status: OrderStatusDto.New,
    created: new Date(),
    owner: null,
    items: [],
    shippingAddress: {
      street: '',
      postalCode: '',
      postalArea: '',
    },
  });
  const [openItemDialog, setOpenItemDialog] = useState(false);

  const orderQuery = useOrderQuery(orderIdToEdit);

  useEffect(() => {
    if (orderQuery.data) {
      setOrder(orderQuery.data);
    } else {
      setOrder({
        id: null,
        status: OrderStatusDto.New,
        created: new Date(),
        owner: null,
        items: [],
        shippingAddress: {
          street: '',
          postalCode: '',
          postalArea: '',
        },
      });
    }
  }, [orderQuery.data, open]);

  const handleSave = () => {
    if (isValid()) {
      onSave(order);
    }
    onClose();
  };

  const isValid = () =>
    order.owner !== null &&
    order.items.length > 0 &&
    order.shippingAddress.street !== '' &&
    order.shippingAddress.postalCode !== '' &&
    order.shippingAddress.postalArea !== '';

  return (
    <>
      <OrderItemDialog
        open={openItemDialog}
        onSave={(item) => {
          setOrder((prevOrder) => ({
            ...prevOrder,
            items: [...prevOrder.items, item],
          }));
        }}
        onClose={() => setOpenItemDialog(false)}
        initialOrderItem={null}
      />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{orderIdToEdit ? 'Redigera order' : 'Lägg till ny order'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Status"
                value={order.status}
                onChange={(e) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    status: Number(e.target.value),
                  }))
                }
                fullWidth
                variant="outlined"
                margin="dense"
                sx={{ mt: 1 }}
              >
                {orderStatusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Skapat datum"
                type="date"
                fullWidth
                variant="outlined"
                margin="dense"
                value={new Date(order.created).toLocaleDateString()}
                onChange={(e) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    created: new Date(e.target.value),
                  }))
                }
              />
            </Grid>
          </Grid>
          <OwnerSelect
            label="Köpare"
            value={order.owner}
            onSelect={(owner) =>
              setOrder((prevOrder) => ({
                ...prevOrder,
                owner: owner,
              }))
            }
          />
          <TextField
            label="Gatuadress"
            type="text"
            fullWidth
            variant="outlined"
            margin="dense"
            value={order.shippingAddress.street}
            onChange={(e) =>
              setOrder((prevOrder) => ({
                ...prevOrder,
                shippingAddress: {
                  ...prevOrder.shippingAddress,
                  street: e.target.value,
                },
              }))
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Postnummer"
                type="text"
                fullWidth
                variant="outlined"
                margin="dense"
                value={order.shippingAddress.postalCode}
                onChange={(e) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    shippingAddress: {
                      ...prevOrder.shippingAddress,
                      postalCode: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Postort"
                type="text"
                fullWidth
                variant="outlined"
                margin="dense"
                value={order.shippingAddress.postalArea}
                onChange={(e) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    shippingAddress: {
                      ...prevOrder.shippingAddress,
                      postalArea: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Artiklar</Typography>
            </Grid>
            <Grid item xs={12} sm={6} textAlign="right">
              <IconButton
                aria-label="Lägg till artikel"
                onClick={() => {
                  setOpenItemDialog(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
          <List>
            {order.items.length === 0 ? (
              <ListItem>
                <Typography variant="body2" color="textSecondary">
                  Inga artiklar tillagda
                </Typography>
              </ListItem>
            ) : (
              <>
                {order.items.map((item, index) => (
                  <ListItem key={index} disableGutters>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Typography variant="body1">{item.name}</Typography>
                      </Grid>
                      <Grid item xs={3} textAlign="right">
                        <Typography variant="body1">{item.quantity} st</Typography>
                      </Grid>
                      <Grid item xs={3} textAlign="right">
                        <Typography variant="body1">
                          {item.price.amount.toFixed(0)} {getCurrencySymbol(item.price.currency)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} textAlign="right">
                        <IconButton
                          aria-label="Ta bort artikel"
                          onClick={() => {
                            const newItems = order.items.filter((_, i) => i !== index);
                            setOrder((prevOrder) => ({
                              ...prevOrder,
                              items: newItems,
                            }));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
                <Divider />
                <ListItem disableGutters>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Typography variant="h6">Totalt</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      <Typography variant="body1">
                        {order.items.reduce((total, item) => total + item.price.amount * item.quantity, 0).toFixed(0)}{' '}
                        {getCurrencySymbol(order.items[0]?.price.currency)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                      <IconButton
                        aria-label="Ta bort alla artiklar"
                        onClick={() => {
                          setOrder((prevOrder) => ({
                            ...prevOrder,
                            items: [],
                          }));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Avbryt</Button>
          <Button disabled={!isValid()} onClick={handleSave}>
            Spara
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
