import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { OwnerSelectOptionDto } from './owners';
import { MoneyDto } from '../../shared/types/money';

const orderKeys = {
  all: ['orders'],
  list: (filter: string) => [...orderKeys.all, 'list', filter],
  details: (id: number) => [...orderKeys.all, 'details', id],
};

export type OrderListItemDto = {
  id: number;
  owner: string;
  totalAmount: MoneyDto;
  created: Date;
};

export type OrderDto = {
  id: number | null;
  status: OrderStatusDto;
  created: Date;
  owner: OwnerSelectOptionDto | null;
  items: OrderItemDto[];
  shippingAddress: AddressDto;
};

export enum OrderStatusDto {
  New = 0,
  Processing = 1,
  Shipped = 2,
  Delivered = 3,
  Canceled = 4,
  Returned = 5,
  OnHold = 6,
}

export interface OrderItemDto {
  id: number | null;
  productId: number;
  name: string;
  quantity: number;
  price: MoneyDto;
}

export interface AddressDto {
  street: string;
  postalCode: string;
  postalArea: string;
}

export const useOrdersQuery = (filter: string) =>
  useQuery({
    queryKey: orderKeys.list(filter),
    queryFn: async () => {
      const response = await apiRequest2<OrderListItemDto[]>({
        action: `Admin/Orders`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
        },
      });

      return response.data;
    },
  });

export const useOrderQuery = (id: number | null) =>
  useQuery({
    enabled: id !== null,
    queryKey: orderKeys.details(id ?? 0),
    queryFn: async () => {
      const response = await apiRequest2<OrderDto>({
        action: `Admin/Orders/${id}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
  });

export const useAddOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (orderDto: OrderDto) =>
      apiRequest2<void>({
        action: `Admin/Orders`,
        method: 'POST',
        allowAnonymous: false,
        body: JSON.stringify(orderDto),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};

export const useUpdateOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (orderDto: OrderDto) =>
      apiRequest2<void>({
        action: `Admin/Orders/${orderDto.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(orderDto),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};

export const useDeleteOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiRequest2<void>({
        action: `Admin/Orders/${id}`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};
