import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  InputAdornment,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { OrderItemDto } from '../../../api/admin/orders';
import { ProductSelect } from '../products/ProductSelect';
import { currency } from '../../../shared/types/money';
import { getCurrencySymbol } from '../../../shared/formatters/money';

export function OrderItemDialog({
  open,
  onSave,
  onClose,
  initialOrderItem,
}: {
  open: boolean;
  onSave: (item: OrderItemDto) => void;
  onClose: () => void;
  initialOrderItem: OrderItemDto | null;
}) {
  const [orderItem, setOrderItem] = useState<OrderItemDto>({
    id: null,
    productId: 0,
    name: '',
    quantity: 0,
    price: { amount: 0, currency },
  });

  useEffect(() => {
    if (initialOrderItem) {
      setOrderItem(initialOrderItem);
    } else {
      setOrderItem({ id: null, productId: 0, name: '', quantity: 0, price: { amount: 0, currency } });
    }
  }, [initialOrderItem, open]);

  const handleSave = () => {
    if (isValid()) {
      onSave(orderItem);
    }
    onClose();
  };

  const isValid = () =>
    orderItem.productId > 0 && orderItem.name !== '' && orderItem.quantity > 0 && orderItem.price.amount > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialOrderItem ? 'Redigera orderrad' : 'Lägg till orderrad'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ProductSelect
              onSelect={(product) => {
                setOrderItem({
                  ...orderItem,
                  productId: product?.id || 0,
                  name: product?.name || '',
                  price: product?.price || { amount: 0, currency: currency },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Antal"
              type="text"
              inputMode="numeric"
              value={orderItem?.quantity || ''}
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setOrderItem({ ...orderItem, quantity: parseInt(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Pris"
              type="text"
              inputMode="numeric"
              value={orderItem?.price.amount || ''}
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setOrderItem({ ...orderItem, price: { amount: parseFloat(e.target.value), currency } })}
              inputProps={{
                sx: { textAlign: 'right' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{getCurrencySymbol(orderItem.price.currency)}</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <Button disabled={!isValid()} onClick={handleSave}>
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
}
