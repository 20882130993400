import { DataGrid } from '@mui/x-data-grid';
import { OrderDto, useAddOrderMutation, useOrdersQuery, useUpdateOrderMutation } from '../../api/admin/orders';
import { DataGridHeader, DefaultButton, FilterInput, PageTitle } from '../../layout';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useDeleteOrderMutation } from '../../api/admin/orders';
import { OrderDialog } from '../../components/admin/orders/OrderDialog';
import { formatMoney } from '../../shared/formatters/money';

export const EditOrdersPage = () => {
  const [filter, setFilter] = useState<string>('');
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [orderIdToEdit, setOrderIdToEdit] = useState<number | null>(null);
  const [orderIdToDelete, setOrderIdToDelete] = useState<number | null>(null);

  const debouncedFilter = useDebounce(filter, 500);

  const ordersQuery = useOrdersQuery(debouncedFilter);
  const addOrderMutation = useAddOrderMutation();
  const updateOrderMutation = useUpdateOrderMutation();
  const deleteOrderMutation = useDeleteOrderMutation();

  return (
    <>
      <OrderDialog
        open={isOrderDialogOpen}
        orderIdToEdit={orderIdToEdit}
        onSave={function (item: OrderDto): void {
          if (orderIdToEdit !== null) {
            updateOrderMutation.mutate({ ...item });
          } else {
            addOrderMutation.mutate({ ...item });
          }
        }}
        onClose={() => {
          setOrderIdToEdit(null);
          setIsOrderDialogOpen(false);
        }}
      />
      <ConfirmDialog
        title="Radera order"
        message="Är du säker på att du vill radera ordern?"
        isOpen={orderIdToDelete !== null}
        onConfirm={() => {
          if (orderIdToDelete !== null) {
            deleteOrderMutation.mutate(orderIdToDelete);
          }
          setOrderIdToDelete(null);
        }}
        onCancel={() => setOrderIdToDelete(null)}
      />
      <PageTitle>Redigera order</PageTitle>
      <DataGridHeader>
        <FilterInput
          type="search"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          placeholder="Filter"
        />

        <DefaultButton onClick={() => setIsOrderDialogOpen(true)}>
          <AddIcon />
          Lägg till ny order
        </DefaultButton>
      </DataGridHeader>

      {ordersQuery.isError && <div>Error: {ordersQuery.error.message}</div>}
      <DataGrid
        columns={[
          {
            field: 'created',
            headerName: 'Datum',
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('sv-SE'),
          },
          { field: 'owner', headerName: 'Köpare', flex: 1 },
          {
            field: 'totalAmount',
            headerName: 'Pris',
            align: 'right',
            type: 'number',
            valueFormatter: (params) => {
              return formatMoney(params.value);
            },
          },
          {
            field: 'actions',
            headerName: '',
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params) => (
              <>
                <IconButton
                  area-label="Ändra"
                  onClick={() => {
                    setOrderIdToEdit(params.row.id);
                    setIsOrderDialogOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton area-label="Radera" onClick={() => setOrderIdToDelete(params.row.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            ),
          },
        ]}
        rows={ordersQuery.data ?? []}
        autoHeight
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={10}
        loading={ordersQuery.isLoading}
        sx={{
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
            marginTop: '1em',
            marginBottom: '1em',
          },
        }}
      />
    </>
  );
};
