import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { OwnerSelectOptionDto, useOwnerSelectOptionsQuery } from '../../../api/admin/owners';
import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { OwnerTypeDto } from '../../../shared/types/owner';

type OwnerSelectProps = {
  label: string;
  value: OwnerSelectOptionDto | null;
  onSelect: (owner: OwnerSelectOptionDto | null) => void;
};

export function OwnerSelect({ label, value, onSelect }: OwnerSelectProps) {
  const [inputValue, setInputValue] = useState<string>('');

  const ownerSelectOptionsQuery = useOwnerSelectOptionsQuery(inputValue);

  return (
    <Autocomplete
      loading={ownerSelectOptionsQuery.isLoading}
      options={ownerSelectOptionsQuery.data ?? []}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_event, newValue) => {
        onSelect(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText="Inga alternativ"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Sök..."
          variant="outlined"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {ownerSelectOptionsQuery.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} value={option.name}>
          {option.type === OwnerTypeDto.Person ? (
            <PersonIcon fontSize="small" sx={{ mr: 1 }} />
          ) : option.type === OwnerTypeDto.Company ? (
            <BusinessIcon fontSize="small" sx={{ mr: 1 }} />
          ) : null}
          {option.name}
        </MenuItem>
      )}
      fullWidth
    />
  );
}
