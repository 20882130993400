import { MoneyDto } from '../types/money';

export const formatMoney = (money: MoneyDto, locale = 'sv-SE') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: money.currency,
    minimumFractionDigits: 0,
  }).format(money.amount);
};

export const getCurrencySymbol = (currency: string, locale = 'sv-SE') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;
};
