import { currency, MoneyDto } from '../../../shared/types/money';
import { ProductDialog } from './ProductDialog';

type AddProductDialogParams = {
  open: boolean;
  onSave: (name: string, description: string | null, price: MoneyDto) => void;
  onClose: () => void;
};

export const AddProductDialog = ({ open, onSave, onClose }: AddProductDialogParams) => {
  return (
    <ProductDialog
      open={open}
      title="Lägg till Produkt"
      initialValues={{ id: 0, name: '', description: null, price: { amount: 0, currency: currency } }}
      onSave={(id: number, name: string, description: string | null, price: MoneyDto): void => {
        onSave(name, description, price);
      }}
      onClose={(): void => {
        onClose();
      }}
    />
  );
};
